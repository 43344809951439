import React from 'react';
import Root from './components/root';

function App() {
  return (
    <div className='container'>
      <Root />
    </div>
  );
}

export default App;
